<template>
    <div :class="type + '-navbar-tooltip'"
        class="absolute z-70 top-11 bg-moozi-2 flex flex-col justify-start gap-1 lg:gap-2 rounded-md right-0 mt-4 md:mt-5 font-helveticaLight tracking-normal w-screen md:w-96 drop-shadow-2xl shadow-2xl cursor-default">
        <div v-if="type == 'gold'" class="p-5">
            <p class="font-bold text-white text-sm">
                Play with <span class="text-secondary-2">GC coins</span> and have the time of your life!
            </p>
            <p class="font-normal text-white text-sm mt-5">
                GC coins can be used to play all games at Moozi! You can also purchase more GC coins at anytime from our
                store to continue your adventure.
                <a href="/gold-coin-shop" target="_blank" rel="noopener noreferrer"
                    class="!font-bold underline underline-offset-2 cursor-pointer">Get Coins</a>.
            </p>
            <div class="bg-moozi-1 rounded-md p-2 mt-5">
                <p class="font-normal text-white text-xs lg:text-sm">
                    Gold coins can not be redeemed for prizes.
                </p>
            </div>
        </div>
        <div v-else class="py-4 px-7">
            <p class="font-bold text-white text-sm">
                Eligible SC are redeemable for <span class="text-tertiary-3">Cash prizes</span>!
            </p>
            <p class="font-normal text-white text-sm mt-5">
                SC must be played at least once before being eligible for redemption as a prize.
                Eligible SC can be redeemed at a rate of SC 1.00 to US $1.00.
            </p>
            <p class="font-normal text-white text-sm mt-5">
                SC are always FREE. No purchase Necessary.
            </p>
            <div class="bg-moozi-1 rounded-md p-2 mt-5">
                <!-- <p class="font-normal text-white text-xs lg:text-sm">
                    Gold coins can not be redeemed for prizes.
                </p> -->
                <div class="font-normal text-white text-xs lg:text-sm">
                    Subject to
                    <a href="/sc-coins" class="underline underline-offset-2" target="_blank" rel="noopener noreferrer">SC Promotional Rules</a>
                    and
                    <a href="/terms-of-use" class="underline underline-offset-2" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import { baseUrl } from "config/services.js";

export default {
    props: ["type", "silver_balance"],
    setup() {

        return {
            baseUrl,
        }
    },
};
</script>
