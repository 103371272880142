<template>
  <div
    class="nav-container fixed top-0 z-60 font-roboto tracking-ider font-bold text-sm xl:text-base 2xl:text-lg bg-moozi-1">
    <nav class="w-full bg-moozi-1 mt-[1px]" style="width: 100vw !important;">
      <div
        class="h-full flex-auto header-bg w-full flex flex-wrap flex-row items-center justify-between mx-auto gap-2 bg-moozi-1">
        <button @click="setMenu(!menuAuth)"
          class="flex-initial flex-wrap items-center justify-between mx-auto p-2 xs:p-4 pl-3 xs:pl-5 text-white hover:text-secondary-3 font-lg rounded-lg text-sm"
          type="button">
          <img class="w-5 xs:w-7 h-5 xs:h-7" :src="baseUrl.img + '/assets/img/header/burgermenu.svg'" alt="" />
        </button>
        <div class="flex-1 header-bg h-full w-full flex flex-wrap items-center justify-between pr-3 xs:!pr-5">
          <span @click="enableScroll(), setMenuOpen()" class="h-full">
            <router-link :to="{ name: 'lobby' }" class="flex items-center space-x-3 rtl:space-x-reverse h-16 md:h-full">
              <img class="w-16 xs:w-18 lg:w-20 -translate-y-1.5 lg:-translate-y-1"
                :src="baseUrl.img + '/assets/img/logo.webp'" alt="" />
            </router-link>
          </span>
          <div class="flex items-center space-x-6">
            <div class="hidden lg:flex items-center justify-between w-auto" id="navbar-cta">
              <div
                class="flex items-center p-0 mt-0 space-x-4 xl:space-x-8 2xl:space-x-12 flex-row md:mt-0 md:border-0">
                <div>
                  <a class="flex items-center" :id="deviceType() == 'mobile' ? '' : 'notification-dewacrm'"
                    @click="deviceType() == 'mobile' ? goToNotifications() : ''" href="javascript:void(0)">
                    <div class="flex items-center" :id="deviceType() == 'mobile' ? '' : 'notification-icon-dewacrm'">
                      <img class="cursor-pointer w-5" :src="baseUrl.img + '/assets/img/header/bell.svg'" alt="" />
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div class="flex flex-row sm:space-x-2 lg:space-x-3">
              <!-- scale-100 hover:scale-105 -->
              <div 
                @click="open_kyc_navigation('from_get_coins'), setMenuOpen()"
                class="h-10 sm:h-11 hidden md:flex flex-row items-center gap-1 rounded-full transition-transform  duration-300 font-roboto font-bold tracking-wider px-2 bg-secondary-3 text-primary-1 text-sm sm:text-base md:text-lg lg:text-base xl:text-lg !cursor-pointer">
                <img class="h-7" :src="baseUrl.img + '/assets/img/header/gc-sc.svg'" alt="">
                <span class="cursor-pointer font-roboto text-sm font-extrabold">Get Coins</span>
              </div>
              <div
                class="h-10 sm:h-11 flex items-center md:relative tracking-widest p-1.5 bg-moozi-2 rounded-full !font-helveticaLight">
                <div @click="setActiveCoinType('gold')"
                  :class="active_coin_type == 'gold' ? 'bg-moozi-4 text-white' : 'bg-transparent text-moozi-6'"
                  class="h-full rounded-full coin-wrapper cursor-pointer" id="gold-coin-trigger">
                  <div class="flex items-center justify-center tracking-wider">
                    <img @click="reloadBalance('gold')" class="cursor-pointer w-7 sm:w-8"
                      :src="baseUrl.img + '/assets/img/header/GC.svg'" alt="" />
                    <span :class="active_coin_type == 'gold' ? '' : 'hidden md:block'"
                      class="pl-2 pr-3 text-xs sm:text-sm font-bold">
                      {{ loadingGold ? '' : money(gold_balance) }}
                      <loading-small :isLoading="loadingGold" />
                    </span>
                  </div>
                  <span class="text-left"></span>
                  <Tooltip :type="'gold'" />
                </div>
                <div @click="setActiveCoinType('silver')"
                  :class="active_coin_type == 'silver' ? 'bg-moozi-4 text-white' : 'bg-transparent text-moozi-6'"
                  class="mx-2 bg-background-2 rounded-full coin-wrapper cursor-pointer" id="silver-coin-trigger">
                  <div class="flex items-center justify-center tracking-wider">
                    <img @click="reloadBalance('silver')" class="cursor-pointer w-7 sm:w-8"
                      :src="baseUrl.img + '/assets/img/header/SC.svg'" alt="" />
                    <span :class="active_coin_type == 'silver' ? '' : 'hidden md:block'"
                      class="pl-2 pr-3 text-xs sm:text-sm font-bold">
                      {{ loadingSilver ? '' : money(silver_balance) }}
                      <loading-small :isLoading="loadingSilver" />
                    </span>
                  </div>
                  <Tooltip :type="'silver'" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <!-- drawer component -->
    <div :style="!menuAuth ? 'left:-256px' : ''" id="drawer-navigation"
      class="fixed left-0 z-60 overflow-hidden w-58 overflow-y-auto transform bg-moozi-2 text-shade-1 ease-in-out transition-all duration-300"
      tabindex="-1" aria-labelledby="drawer-navigation-label">
      <div class="overflow-y-auto overflow-x-hidden h-full flex flex-col relative">
        <div class="bg-moozi-2 drop-shadow-xl">
          <div class="px-6 pb-4">
            <div class="pt-8 pb-6">
              <img class="mx-auto w-5/12" :src="baseUrl.img + '/assets/img/header/avatar.png'" alt="" />
            </div>
            <div class="flex justify-start items-center gap-2">
              <div class="flex justify-start flex-col items-left text-left overflow-hidden">
                <p
                  class="cursor-pointer text-sm leading-5 font-bold text-white text-nowrap text-ellipsis overflow-hidden">
                  {{ isUser?.username }}
                </p>
                <p
                  class="mt-1 cursor-pointer text-xs leading-3 font-normal text-white text-nowrap text-ellipsis overflow-hidden">
                  {{ isUser?.email }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col items-start h-full justify-between">
          <div class="flex-1 w-full">
            <ul class="left-navigation-container font-medium px-6 pt-3">
              <li>
                <a @click="open_kyc_navigation('from_get_coins'), setMenuOpen()"
                  class="flex items-center py-2 rounded-lg group cursor-pointer get-coins">
                  <div
                    class="w-full h-10 sm:h-11 flex flex-row items-center justify-center gap-1 rounded-full transition-transform  duration-300 font-roboto font-bold tracking-wider px-2 bg-secondary-3 text-primary-1 text-sm sm:text-base md:text-lg lg:text-base xl:text-lg !cursor-pointer">
                    <img class="h-5 sm:h-7" :src="baseUrl.img + '/assets/img/header/gc-sc.svg'" alt="">
                    <span class="cursor-pointer font-roboto text-xs sm:text-sm font-extrabold">Get
                      Coins</span>
                  </div>
                </a>
              </li>
              <li @click="enableScroll(), setMenuOpen()">
                <router-link :to="{ name: 'lobby' }" class="flex items-center py-2 rounded-lg group"
                  :class="isUrl('lobby') ? 'active-left-navigation' : ''">
                  <div class="h-7 sm:h-8 w-7 sm:w-8 content-center">
                    <img :src="baseUrl.img + '/assets/img/header/games.svg'" />
                  </div>
                  <span class="ms-3 font-bold text-sm sm:text-base">Lobby</span>
                </router-link>
              </li>
              <li @click="enableScroll(), setMenuOpen()">
                <router-link :to="{ name: 'promotion' }" class="flex items-center py-2 rounded-lg group"
                  :class="isUrl('promotion') ? 'active-left-navigation' : ''">
                  <div class="h-7 sm:h-8 w-7 sm:w-8 content-center">
                    <img :src="baseUrl.img + '/assets/img/header/promotion.svg'" />
                  </div>
                  <span class="ms-3 font-bold text-sm sm:text-base">Promotions</span>
                </router-link>
              </li>
              <li>
                <a @click="setMenuOpen(), open_kyc_navigation('from_redeem')"
                  class="flex items-center py-2 rounded-lg group cursor-pointer"
                  :class="isUrl('redeem') ? 'active-left-navigation' : ''">
                  <div class="h-7 sm:h-8 w-7 sm:w-8 content-center">
                    <img :src="baseUrl.img + '/assets/img/header/redeem.svg'" />
                  </div>
                  <span class="ms-3 font-bold text-sm sm:text-base">Redeem</span>
                </a>
              </li>
              <li @click="enableScroll(), setMenuOpen()">
                <router-link :to="{ name: 'support' }" class="flex items-center py-2 rounded-lg group"
                  :class="isUrl('support') ? 'active-left-navigation' : ''">
                  <div class="h-7 sm:h-8 w-7 sm:w-8 content-center">
                    <img :src="baseUrl.img + '/assets/img/header/support.svg'" />
                  </div>
                  <span class="ms-3 font-bold text-sm sm:text-base">Support</span>
                </router-link>
              </li>
              <li>
                <a @click="openMyAccount(), setMenuOpen()" class="flex items-center py-2 rounded-lg group cursor-pointer">
                  <div class="h-7 sm:h-8 w-7 sm:w-8 content-center">
                    <img :src="baseUrl.img + '/assets/img/header/account.svg'" />
                  </div>
                  <span class="ms-3 font-bold text-sm sm:text-base">My Account</span>
                </a>
              </li>
              <li>
                <a @click="goToNotifications(), setMenuOpen()" class="flex items-center py-2 rounded-lg group cursor-pointer">
                  <div class="h-7 sm:h-8 w-7 sm:w-8 content-center">
                    <img :src="baseUrl.img + '/assets/img/header/bell.svg'" />
                  </div>
                  <span class="ms-3 font-bold text-sm sm:text-base">Notification</span>
                </a>
              </li>
            </ul>
          </div>
          <div class="mt-10 mb-10 w-full text-white cursor-pointer transform ease-in-out transition-all duration-300">
            <div class="w-full flex flex-wrap items-center justify-center">
              <div @click="logout(), setMenuOpen()" class="text-center rounded-full cursor-pointer">
                <span class="cursor-pointer text-moozi-6 text-sm sm:text-base">Log Out</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Kyc v-model:isUser="isUser" />
    <DailyBonus :isClaimed="isClaimed" />
    <SocialMediaBonus />
    <WelcomeBonus />
    <idVerification />
    <Timer :onClaimStatus="onClaimStatus" />
    <Terms />
    <FreeGCTopUp />
    <SessionExpired />
    <Reminder />
    <VerifyEmail />
  </div>
</template>

<script>
import {
  ref,
  computed,
  watch,
  onMounted,
  reactive,
  onBeforeUnmount
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { useFormatter } from "composables/use-formatter.js";
import eventBus from "plugins/event.js";
import Kyc from "components/modals/kyc.vue";
import DailyBonus from "components/modals/dailyBonus.vue";
import SocialMediaBonus from "components/modals/socialMediaBonus.vue";
import WelcomeBonus from "components/modals/welcomeBonus.vue";
import { authStore } from "store/auth.js";
import LoadingSmall from "@/components/loading/loadingSmall.vue";
import Timer from "components/timer/index.vue";
import idVerification from "components/modals/idVerification.vue";
import { useBalanceSocketInit } from "composables/use-balance-socket-init.js";
import Terms from "components/modals/terms.vue";
import FreeGCTopUp from "components/modals/freeGCTopUp.vue";
import SessionExpired from "components/modals/sessionExpired.vue";
import Reminder from "components/modals/reminder.vue";
import VerifyEmail from "components/modals/verifyEmail.vue";
import { baseUrl } from "config/services.js";
import Tooltip from "./component/tooltip.vue";
import { utilitiesStore } from "store/utilities.js";
import device from 'middleware/device.js';
import { useKYC } from 'composables/use-kyc.js';

export default {
  components: {
    Kyc,
    DailyBonus,
    SocialMediaBonus,
    WelcomeBonus,
    LoadingSmall,
    Timer,
    idVerification,
    Terms,
    FreeGCTopUp,
    SessionExpired,
    Reminder,
    Tooltip,
    VerifyEmail
  },
  props: [],
  setup() {
    // const { destroySocket } = useBalanceSocketInit();
    const { open_kyc_navigation } = useKYC();

    const useUtilitiesStore = utilitiesStore();

    const route = useRoute();
    const router = useRouter();

    const useAuthStore = authStore();

    const mountFlag = ref(false);

    const loadingGold = ref(false);
    const loadingSilver = ref(false);

    const isClaimed = ref(false);

    const { money } = useFormatter();
    const { deviceType } = device();

    const apiParams = reactive({
      type: "",
    });

    const isUser = computed(() => {
      return useAuthStore.user;
    });

    const active_coin_type = computed(() => {
      return useAuthStore.active_coin_type || route.params.type;
    });

    const menuAuth = computed(() => {
      return useUtilitiesStore.menuAuth;
    });

    const reloadBalance = async (val1) => {
      if (val1 == "gold") {
        loadingGold.value = true;
        apiParams.type = "gold_coins";
        try {
          await useAuthStore.reloadBalance(apiParams);
          loadingGold.value = false;
        } catch (error) {
          console.error("Error gatting balance:", error);
        }
      } else {
        loadingSilver.value = true;
        apiParams.type = "silver_coins";
        try {
          await useAuthStore.reloadBalance(apiParams);
          loadingSilver.value = false;
        } catch (error) {
          console.error("Error gatting balance:", error);
        }
      }
    };

    const gold_balance = computed(() => {
      return useAuthStore.gold_balance;
    });

    const silver_balance = computed(() => {
      return parseFloat(useAuthStore.silver_balance) + parseFloat(useAuthStore.silver_balance_redeem);
    });


    const isUrl = (...urls) => {
      let currentUrl = route.path.substr(1);
      if (urls[0] === "") {
        return currentUrl === "";
      }
      return urls.filter((url) => currentUrl.startsWith(url)).length;
    };

    const openDailyBonus = () => {
      eventBus.emit("open:dailybonus");
      router.replace({ query: { modal: "dailybonus" } });
    };

    const openSocialMediaBonus = () => {
      eventBus.emit("open:socialmediabonus");
      router.replace({ query: { modal: "socialmediabonus" } });
    };

    const openMyAccount = () => {
      enableScroll()
      router.push({ path: "/account", query: { tab: "account-details" } });
    };

    const openWelcomeBonus = () => {
      eventBus.emit("open:welcomebonus");
      router.replace({ query: { modal: "welcomebonus" } });
    };

    const onClaimStatus = (data) => {
      isClaimed.value = data;
    };

    const logout = () => {
      useAuthStore.logout().then(() => {
        // router.push({ path: "/signin" });
        window.location.href = "/signin"
      });
    };

    const setMenu = (data) => {
      useUtilitiesStore.setMenuAuth(data)
    }

    const myEventHandler = () => {
      if (deviceType() == "mobile") {
        setMenu(false);
      } else {
        if(window.innerWidth < 1024) {
          setMenu(false);
          return;
        } 
        setMenu(true);
      }
    };

    const setMenuOpen = () => {
      if (deviceType() == "mobile") {
        setMenu(false);
      } 
    };

    const goToNotifications = () => {
      enableScroll()
      window.location.href = "notification";
    };

    const navigateLink = (path) => {
      enableScroll();
      switch (path) {
        case "home":
          router.push({ path: "/" });
          break;
        case "games":
          router.push({ path: "/games" });
          break;
        case "promotion":
          router.push({ path: "/promotion" });
          break;
        case "support":
          router.push({ path: "/support" });
          break;
        case "redeem":
          open_kyc_navigation("from_redeem");
          break;
        default:
          break;
      }
    };

    const enableScroll = () => {
      useUtilitiesStore.enableScroll();
    };

    const hoverCoin = (id, otherTrigger) => {
      const element = document.getElementById(id);
      const tempTrigger = document.getElementById(otherTrigger);

      element.addEventListener("mouseover", () => {
        element.classList.remove('delay');

        if (tempTrigger.classList.contains('show')) {
          tempTrigger.classList.add('delay')
          tempTrigger.classList.remove('show')
        }

        element.classList.add('show');
      });

      element.addEventListener("mouseleave", function () {
        element.classList.remove('show');
      });
    }

    const setActiveCoinType = (type) => {
      if (active_coin_type.value != type) {
        setMenu(false);
        useAuthStore.setActiveCoinType(type);
        onLaunch(type);
      }
    };

    onMounted(() => {
      hoverCoin('gold-coin-trigger', 'silver-coin-trigger');
      hoverCoin('silver-coin-trigger', 'gold-coin-trigger');
      myEventHandler();
      window.addEventListener("resize", function () {
        myEventHandler()
      });
    });

    watch(isUser, (newVal) => {
      if (newVal && !mountFlag.value) {
        useAuthStore.initWalletBalance();
        if (route.query.modal == "kyc") {
          eventBus.emit("open:kyc");
        } else if (route.query.modal == "idverification") {
          eventBus.emit("open:idverification");
        }
        mountFlag.value = true;
      }
    });

    onBeforeUnmount(() => {
      destroySocket();
    });

    return {
      isUser,
      isUrl,
      open_kyc_navigation,
      openDailyBonus,
      openSocialMediaBonus,
      openMyAccount,
      openWelcomeBonus,
      logout,
      gold_balance,
      silver_balance,
      reloadBalance,
      loadingGold,
      loadingSilver,
      money,
      goToNotifications,
      onClaimStatus,
      isClaimed,
      navigateLink,
      baseUrl,
      enableScroll,
      active_coin_type,
      setActiveCoinType,
      setMenuOpen,
      deviceType,
      menuAuth,
      setMenu
    };
  },
};
</script>
