<template>
     <PreLoadingOverlay />
     <HeaderAfterLogin v-if="token"/>
     <HeaderGuest v-else/>
     <div 
          v-if="token"
          :class="[menuAuth ? 'ml-0 md:ml-58' : 'ml-0']" 
          class="flex justify-center px-5 sm:px-8 md:px-10 lg:px-12 xl:px-14 2xl:px-18 transition-all duration-300 ease-in-out"
     > 
          <div class="mt-16 w-full md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl 2xl:max-w-screen-2xl transition-all duration-300 ease-in-out">
               <router-view />
          </div>
     </div>
     <div 
          v-else-if="!checkGuestPage"
          :class="[menuGuest ? 'ml-0 ml-58' : 'ml-0']" 
          class="flex justify-center px-5 sm:px-8 md:px-10 lg:px-12 xl:px-14 2xl:px-18 transition-all duration-300 ease-in-out"
     > 
          <div class="mt-16 w-full md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl 2xl:max-w-screen-2xl transition-all duration-300 ease-in-out">
               <router-view />
          </div>
     </div>
     <div v-else-if="checkGuestPage" :class="[menuGuest ? 'ml-58' : 'ml-0']" >
          <div class="mt-16 ">
               <router-view />
          </div>
     </div>
     <FooterLayout />
</template>

<script>
import HeaderGuest from "components/navigation/guest/index.vue";
import HeaderAfterLogin from "components/navigation/afterlogin/index.vue";
import FooterLayout from "components/footer/index.vue";
import { ref, computed, onMounted, watch } from "vue";
import { authStore } from "store/auth.js";
import eventBus from "plugins/event.js";
import _ from "underscore";
import Cookies from "js-cookie";
import { useRoute } from "vue-router";
import PreLoadingOverlay from "components/loading/preLoadingOverlay.vue";
import { utilitiesStore } from "store/utilities.js";
import { bonusStore } from "store/bonus.js";

export default {
     components: {
          HeaderGuest,
          HeaderAfterLogin,
          FooterLayout,
          PreLoadingOverlay,
     },
     setup() {
          const route = useRoute();
          const useAuthStore = authStore();
          const useUtilitiesStore = utilitiesStore();
          const useBonusStore = bonusStore();

          const checkGuestPage = ref(false);

          const token = computed(() => {
               if(useAuthStore.token) {
                    menuAuth.value = true
               } else {
                    menuGuest.value = true    
               }
               return useAuthStore.token;
          });

          const user = computed(() => {
               return useAuthStore.user;
          });

          const isEmailVerified = computed(() => {
               return useAuthStore.isEmailVerified;
          });

          const loading = computed(() => {
               return useUtilitiesStore.loading;
          });

          const menuAuth = computed(() => {
               return useUtilitiesStore.menuAuth;
          });

          const menuGuest = computed(() => {
               return useUtilitiesStore.menuGuest;
          });

          const checkAuthPath = () => {
               let path = window.location.pathname;
               checkGuestPage.value = ['signin', 'register', 'forgot'].includes(path.replaceAll('/', ''));
          };

          watch(user, (newVal) => {
               if (newVal?.meta) {
                    if (route.name == "home") {
                         if (route.query.modal == "dailybonus") {
                              return;
                         }

                         let filter = _.filter(newVal.meta, function (params) {
                              return params["meta_key"] == "welcome_bonus";
                         });

                         if (filter.length == 0) {
                              setTimeout(async () => {
                                   await useBonusStore.getWelcome();
                              }, 0)

                              setTimeout(() => {
                                   if (Cookies.get("welcome") == null) {
                                        if (isEmailVerified.value) {
                                             // var currentTime = new Date();
                                             eventBus.emit("open:welcomebonus");
                                             // Cookies.set("welcome", true, {
                                             //      expires: new Date(
                                             //           currentTime.getTime() +
                                             //                60 * 60 * 1000
                                             //      ),
                                             // });
                                        }
                                   }
                              }, 3000);
                         }
                    }

                    let filter = _.filter(newVal.meta, function (params) {
                         return params["meta_key"] == "free_gc_topup";
                    });

                    if (filter.length >= 1) {
                         if (!loading.value) {
                              useBonusStore.claimFreeGC()
                         }
                    }
               }
          });

          watch(loading, (newVal) => {
               if (!newVal) {
                    if (user?.value?.meta) {
                         let filter = _.filter(user.value.meta, function (params) {
                              return params["meta_key"] == "free_gc_topup";
                         });


                         if (filter.length >= 1) {
                              useBonusStore.claimFreeGC()
                         }
                    }
               }
          });

          watch(route, (newVal) => {
               if (newVal) {
                    checkAuthPath();
               }
          });

          onMounted(() => {
               checkAuthPath();
               if (!loading.value) {
                    return;
               }
               useUtilitiesStore.preLoading(true);
               useUtilitiesStore.disableScroll();

               setTimeout(() => {
                    useUtilitiesStore.preLoading(false);
                    useUtilitiesStore.enableScroll();
               }, 3000);
          });

          return {
               token,
               menuAuth,
               menuGuest,
               checkAuthPath,
               checkGuestPage
          };
     },
};
</script>
