<template>
    <div
        class="nav-container fixed top-0 z-60 font-roboto tracking-ider font-bold text-sm xl:text-base 2xl:text-lg bg-moozi-1">
        <nav class="w-full bg-moozi-1 mt-[1px]" style="width: 100vw !important;">
            <div
                class="h-full flex-auto header-bg w-full flex flex-wrap flex-row items-center justify-between mx-auto gap-2 bg-moozi-1">
                <button @click="setMenu(!menuGuest)"
                    class="flex-initial flex-wrap items-center justify-between mx-auto p-2 xs:p-4 pl-3 xs:pl-5 text-white hover:text-secondary-3 font-lg rounded-lg text-sm"
                    type="button">
                    <img class="w-5 xs:w-7 h-5 xs:h-7" :src="baseUrl.img + '/assets/img/header/burgermenu.svg'"
                        alt="" />
                </button>

                <div class="flex-1 header-bg h-full w-full flex flex-wrap items-center justify-between pr-3 xs:!pr-5">
                    <span @click="enableScroll()" class="h-full">
                        <router-link :to="{ name: 'lobby' }"
                            class="flex items-center space-x-3 rtl:space-x-reverse h-16 md:h-full">
                            <img class="w-16 xs:w-18 lg:w-20 -translate-y-1.5 lg:-translate-y-1"
                                :src="baseUrl.img + '/assets/img/logo.webp'" alt="" />
                        </router-link>
                    </span>
                    <div class="flex items-center space-x-6">
                        <div class="flex flex-row space-x-2 lg:space-x-3">
                            <!-- scale-100 hover:scale-105 -->
                            <router-link @click="setMenuOpen('login')" :to="{ name: 'login' }" class="w-max">
                                <button type="button"
                                class="h-10 sm:h-11 flex flex-row items-center gap-1 rounded-full transition-transform  duration-300 font-roboto font-bold tracking-wider px-2 bg-secondary-3 text-primary-1 text-sm sm:text-base md:text-lg lg:text-base xl:text-lg !cursor-pointer">
                                    <img class="h-5 sm:h-7" :src="baseUrl.img + '/assets/img/header/gc-sc.svg'" alt="">
                                    <span class="cursor-pointer font-roboto text-xs sm:text-sm font-extrabold">Get
                                        Coins</span>
                                </button>
                            </router-link>
                            <router-link @click="setMenuOpen('login')" :to="{ name: 'login' }" class="w-max">
                                <button type="button"
                                    class="text-xs sm:text-sm h-10 sm:h-11 text-secondary-3 bg-background-4 px-6 py-2.5 text-center leading-tight shadow-xl rounded-full w-full font-roboto font-bold">Log
                                    In</button>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
        <!-- drawer component -->
        <div :style="!menuGuest ? 'left:-256px' : ''" id="drawer-navigation"
            class="fixed left-0 z-60 overflow-hidden w-64 overflow-y-auto transform bg-moozi-2 text-shade-1 ease-in-out transition-all duration-300"
            tabindex="-1" aria-labelledby="drawer-navigation-label">
            <div class="overflow-y-auto overflow-x-hidden h-full flex flex-col relative">
                <div class="bg-moozi-2 drop-shadow-xl">
                    <div class="px-6 pb-4">
                        <div class="py-8">
                            <img class="mx-auto w-6/12" :src="baseUrl.img + '/assets/img/header/avatar.png'" alt="" />
                        </div>
                        <div class="flex justify-center items-center gap-2">
                            <p
                                class="cursor-pointer text-sm leading-5 font-bold text-white text-nowrap text-ellipsis overflow-hidden">
                                Guest
                            </p>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col items-start h-full justify-between">
                    <div class="flex-1 pt-6 w-full">
                        <ul class="left-navigation-container font-medium px-6">
                            <li>
                                <router-link @click="setMenuOpen('gold-coin-shop')" :to="{ name: 'gold-coin-shop' }"
                                    class="flex items-center py-2 rounded-lg group cursor-pointer get-coins">
                                    <div
                                        class="w-full h-10 sm:h-11 flex flex-row items-center justify-center gap-1 rounded-full transition-transform  duration-300 font-roboto font-bold tracking-wider px-2 bg-secondary-3 text-primary-1 text-sm sm:text-base md:text-lg lg:text-base xl:text-lg !cursor-pointer">
                                        <img class="h-5 sm:h-7" :src="baseUrl.img + '/assets/img/header/gc-sc.svg'"
                                            alt="">
                                        <span class="cursor-pointer font-roboto text-xs sm:text-sm font-extrabold">Get
                                            Coins</span>
                                    </div>
                                </router-link>
                            </li>
                            <li @click="enableScroll(), setMenuOpen()">
                                <router-link :to="{ name: 'lobby' }" class="flex items-center py-2 rounded-lg group"
                                    :class="isUrl('lobby') ? 'active-left-navigation' : ''">
                                    <div class="h-6 sm:h-8 w-6 sm:w-8 content-center">
                                        <img :src="baseUrl.img + '/assets/img/header/games.svg'" />
                                    </div>
                                    <span class="ms-3 font-bold text-sm sm:text-base">Lobby</span>
                                </router-link>
                            </li>
                            <li @click="enableScroll(), setMenuOpen()">
                                <router-link :to="{ name: 'promotion' }" class="flex items-center py-2 rounded-lg group"
                                    :class="isUrl('promotion') ? 'active-left-navigation' : ''">
                                    <div class="h-6 sm:h-8 w-6 sm:w-8 content-center">
                                        <img :src="baseUrl.img + '/assets/img/header/promotion.svg'" />
                                    </div>
                                    <span class="ms-3 font-bold text-sm sm:text-base">Promotions</span>
                                </router-link>
                            </li>
                            <li @click="enableScroll(), setMenuOpen()">
                                <router-link :to="{ name: 'support' }" class="flex items-center py-2 rounded-lg group"
                                    :class="isUrl('support') ? 'active-left-navigation' : ''">
                                    <div class="h-6 sm:h-8 w-6 sm:w-8 content-center">
                                        <img :src="baseUrl.img + '/assets/img/header/support.svg'" />
                                    </div>
                                    <span class="ms-3 font-bold text-sm sm:text-base">Support</span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                    <div
                        class="mt-10 mb-10 w-full text-white cursor-pointer transform ease-in-out transition-all duration-300">
                        <div class="flex flex-col items-center justify center gap-2 px-6">
                            <router-link @click="setMenuOpen('register')" :to="{ name: 'register' }" class="flex-1 w-full">
                                <button type="button"
                                    class="text-sm h-10 sm:h-11 text-primary-1 bg-secondary-3 px-1 py-2.5 text-center leading-tight shadow-xl rounded-full w-full font-roboto font-bold">Sign
                                    Up</button>
                            </router-link>
                            <router-link @click="setMenuOpen('login')" :to="{ name: 'login' }" class="flex-1 w-full">
                                <button type="button"
                                    class="text-sm h-10 sm:h-11 text-secondary-3 bg-background-4 px-1 py-2.5 text-center leading-tight shadow-xl rounded-full w-full font-roboto font-bold">Log
                                    In</button>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref, computed } from 'vue';
import { useRoute } from 'vue-router'
import { baseUrl } from "config/services.js";
import device from 'middleware/device.js';
import { utilitiesStore } from "store/utilities.js";

export default {
    setup() {
        const sidebarOpen = ref(false);
        const route = useRoute();

        const useUtilitiesStore = utilitiesStore();

        const { deviceType } = device();

        const enableScroll = () => {
            useUtilitiesStore.enableScroll();
        };

        const menuGuest = computed(() => {
            return useUtilitiesStore.menuGuest;
        });

        const isUrl = (...urls) => {
            let currentUrl = route.path.substr(1)
            if (urls[0] === '') {
                return currentUrl === ''
            }
            return urls.filter((url) => currentUrl.startsWith(url)).length
        };

        const setMenu = (data) => {
            useUtilitiesStore.setMenuGuest(data)
        }

        const myEventHandler = () => {
            if (deviceType() == "mobile") {
                setMenu(false);
            } else {
                if(['login', 'register', 'forgot'].includes(route.name)) {
                    setMenu(false);
                    return
                }
                
                if(window.innerWidth < 1024) {
                    setMenu(false);
                    return;
                } 

                setMenu(true);
            }
        };

        const setMenuOpen = (type = null) => {
            if(['login', 'register', 'forgot'].includes(type)) {
                setMenu(false);
                return
            }
            if (deviceType() == "mobile") {
                setMenu(false);
            }
        };

        onMounted(() => {
            myEventHandler();
            window.addEventListener("resize", function () {
                myEventHandler()
            });
        });

        return {
            sidebarOpen,
            isUrl,
            baseUrl,
            setMenuOpen,
            enableScroll,
            menuGuest,
            setMenu
        }
    }
};
</script>
